<template>
  <div class="ct-rule-text">
    <slot />
  </div>
</template>

<script>

export default {
  name: 'CtRuleText',
  props: {
    direction: {
      type: String,
      default: 'horizontal',
    },
  },
}
</script>

<style lang="scss" scoped>
  .ct-rule-text {
    margin: 20px 0;
    height: 1px;
    padding: 0;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    > :first-child {
      flex-grow: 0 !important;
      margin: 0 !important;
      padding: 0 20px !important;
      background: white;
    }
  }
</style>
